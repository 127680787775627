<template>
  <section class="home d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="home-text">
            <h1>CT SCAN dan MRI</h1>
            <p>
              CT Scan menggabungkan serangkaian pemindaian X-ray yang diambil
              dari berbagai sudut yang berbeda. Kemudian komputer akan memproses
              sehingga menghasilkan gambar tulang dan jaringan lunak di dalam
              tubuh.
            </p>

            <p>Membantu Proses Diagnosis</p>
            <br />
            <p>
              CT scan adalah mesin pemindai berbentuk lingkaran yang besar,
              cukup untuk dimasuki orang dewasa dengan posisi berbaring. Alat
              ini dapat digunakan untuk mendiagnosis dan memonitor beragam
              kondisi kesehatan. Kemungkinan dokter akan merekomendasikan CT
              scan untuk dilakukan pada saat-saat tertentu, seperti:
            </p>

            <p>
              - Melakukan diagnosis kelainan otot dan tulang, seperti tumor atau
              retak pada tulang.
            </p>

            <p>- Menentukan lokasi tumor, infeksi, atau bekuan darah.</p>
            <p>
              - Memandu prosedur medis ketika melakukan operasi, biopsi, atau
              terapi radiasi.
            </p>

            <p>
              - Mendeteksi dan memonitor kondisi dan penyakit tertentu, seperti
              kanker, sakit jantung, nodul pada paru-paru, dan massa pada hati.
            </p>

            <p>- Mencari tahu cedera atau pendarahan internal.</p>
            <br />
            <p>
              Penggunaan CT scan kini juga makin banyak digunakan sebagai
              pemindaian penyakit atau sebagai langkah preventif. Misalnya CT
              scan pada usus besar bagi pasien yang memiliki risiko tinggi
              terkena kanker usus besar atau pemindaian jantung secara lengkap
              pada pasien dengan risiko tinggi penyakit jantung.
            </p>
            <br />

            <p>
              Magnetic Resonance Imaging, MRI) ialah gambaran bagian badan yang
              diambil dengan menggunakan daya magnet yang kuat mengelilingi
              anggota badan tersebut. Berbeda dengan “CT scan“, MRI tidak
              menggunakan radiasi Sinar-X dan cocok untuk mendeteksi Jaringan
              Lunak, misalnya Kista ataupun Tumor yang masih sedikit, tetapi
              pencitraan dengan MRI lebih mahal daripada menggunakan CT scan.
            </p>
            <br />

            <p>
              Magnetic Resonance Imaging (MRI) merupakan suatu teknik yang
              digunakan untuk menghasilkan gambar organ dalam pada organisme
              hidup dan juga untuk menemukan jumlah kandungan air dalam struktur
              geologi. Biasa digunakan untuk menggambarkan secara patologi atau
              perubahan fisiologi otot hidup dan juga memperkirakan ketelusan
              batu kepada hidrokarbon.
            </p>
            <br />

            <p>
              Salah satu kelebihan pencitraan MRI adalah, menurut pengetahuan
              pengobatan masa kini, tidak berbahaya kepada orang yang sakit. MRI
              hanya menggunakan medan magnet kuat dan pancarannya tidak mengion
              dalam jalur frekuensi radio. Bagaimanapun, perlu diketahui bahwa
              pasien yang membawa benda asing logam (seperti serpihan peluru)
              atau implant tertanam (seperti tulang Titanium buatan, atau
              pacemaker) tidak boleh dipindai di dalam mesin MRI, disebabkan
              penggunaan medan megnet yang kuat.
            </p>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <img :src="require('../../assets/layanan/ct.jpg')" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    document.title = "CT SCAN MRI RSUD GAMBIRAN KOTA KEDIRI";
  },
};
</script>
